import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        console.log('taxentey controler started')
    }

    taxChange(event){
        // console.log('name=' + event.srcElement.name + ' tax=' + event.srcElement.value + ' prior tax=' + event.srcElement)
        // console.log('currentValue = ' + event.srcElement.parentElement.querySelectorAll('td.currentValue input').value);
        var taxEntryRows = document.querySelectorAll('.taxEntryRow');
        var currentTax = event.target.value;
        var currentValue = event.target.parentElement.parentElement.querySelector('td.currentValue input').value;
        var priorTax = event.target.parentElement.parentElement.querySelector('td.priorTax').innerText;
        console.log(event);
        if (event.target.hasAttribute('data-empty')) {
            event.target.removeAttribute('data-empty');
            for (var r = 0; r < taxEntryRows.length; r++) {
                // if the prior tax of this row = the prior tax of the changed row then
                //      set the currentTax to the currentTax of the changed row
                //      and set the currentValue to the currentValue of the changed row
                //console.log(taxEntryRows[r].querySelector('td.priorTax').innerHTML);
                if (taxEntryRows[r].querySelector('td.priorTax').innerHTML == priorTax) {
                    taxEntryRows[r].querySelector('td.currentTax input').value = currentTax;
                    taxEntryRows[r].querySelector('td.currentTax input').removeAttribute('data-empty');
                    taxEntryRows[r].querySelector('td.currentValue input').value = currentValue;
                }
            }
        }
        this.updateTotals();
    }

    valueChange(event) {
        var taxEntryRows = document.querySelectorAll('.taxEntryRow');
        var currentValue = event.target.parentElement.parentElement.querySelector('td.currentValue input').value;
        var priorValue = event.target.parentElement.parentElement.querySelector('td.priorValue').innerText;
        if (event.target.hasAttribute('data-empty')) {
            event.target.removeAttribute('data-empty');
            for (var r = 0; r < taxEntryRows.length; r++) {
                // if the prior value of this row = the prior value of the changed row then
                //      set the currentValue to the currentValue of the changed row
                //console.log(taxEntryRows[r].querySelector('td.priorValue').innerHTML);
                if (taxEntryRows[r].querySelector('td.priorValue').innerHTML == priorValue) {
                    taxEntryRows[r].querySelector('td.currentValue input').value = currentValue;
                    taxEntryRows[r].querySelector('td.currentValue input').removeAttribute('data-empty');
                }
            }
        }
        this.updateTotals();
    }

    rowCheck(event) {
        console.log('row check');
        this.updateTotals();
    }

    updateTotals() {
        var taxEntryRows = document.querySelectorAll('.taxEntryRow');
        var currentValueTotal = 0;
        var currentTaxTotal = 0;
        var checkedCount = 0;
        var checkedValueTotal = 0;
        var checkedTaxTotal = 0;
        // if this is a Notice of Value entry, there may not be ant tax related fields
        for (var r = 0;r < taxEntryRows.length; r++) {
            var currentValue = parseFloat(taxEntryRows[r].querySelector('td.currentValue input').value);
            if (!isNaN(currentValue)) currentValueTotal += currentValue;
                if (taxEntryRows[r].querySelector('td.currentTax input') !== null) {
                    var currentTax = parseFloat(taxEntryRows[r].querySelector('td.currentTax input').value);
                    if (!isNaN(currentTax)) currentTaxTotal += currentTax;
                }
            if (taxEntryRows[r].querySelector('td.rowChecked input').checked) {
                checkedCount++;
                var checkedValue = parseFloat(taxEntryRows[r].querySelector('td.currentValue input').value);
                if (!isNaN(checkedValue)) checkedValueTotal += checkedValue;
                if (taxEntryRows[r].querySelector('td.currentTax input') !== null) {
                    var checkedTax = parseFloat(taxEntryRows[r].querySelector('td.currentTax input').value);
                    if (!isNaN(checkedTax)) checkedTaxTotal += checkedTax;
                }
            }
        }
        document.querySelector('.checked-row-count').innerText = checkedCount; //number format
        document.querySelector('.current-value-total').innerText = currentValueTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD', 'maximumFractionDigits': 0 });
        document.querySelector('.checked-value-total').innerText = checkedValueTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD', 'maximumFractionDigits': 0 });
        if (document.querySelector('.current-tax-total') !== null) {
            document.querySelector('.current-tax-total').innerText = currentTaxTotal.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
            });
            document.querySelector('.checked-tax-total').innerText = checkedTaxTotal.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
            });
        }
        if (document.querySelector('.check-amt') !== null) {
            document.querySelector('.check-amt').value = checkedTaxTotal.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
            });
        }
    }

}
