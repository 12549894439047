import { Controller } from '@hotwired/stimulus';
import axios from 'axios';


export default class extends Controller {
    static targets = ['parcelId', 'propertyBasicInfo', 'propertyId', 'year'];

    connect() {
        console.log('add-offer');
        const propertyAutocomplete = document.getElementById("property-autocomplete");
        propertyAutocomplete.addEventListener("autocomplete.change", this.propertySelected.bind(this))
    }

    propertySelected(event) {
        event.preventDefault();
        this.propertyIdTarget.value = event.detail.value;
        const form = document.addValueForm;
        form.requestSubmit();
    }

    addValue(event) {
        console.log(event);
    }

    propId(event) {
        this.parcelIdTarget.innerText = event.srcElement.value;
    }
}
