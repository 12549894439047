import { Controller } from '@hotwired/stimulus';
import axios from 'axios';


export default class extends Controller {
    static targets = ['parcelId', 'propertyBasicInfo', 'propertyId', 'year', 'county', 'taxyear', 'entrytype', 'note', 'paymentdate'];

    connect() {
        console.log('add-taxes');
        const propertyAutocomplete = document.getElementById("property-autocomplete");
        propertyAutocomplete.addEventListener("autocomplete.change", this.propertySelected.bind(this))
    }

    propertySelected(event) {
        event.preventDefault();
        this.propertyIdTarget.value = event.detail.value;
        console.log('Property selected id='+this.propertyIdTarget.value);
        const form = document.TaxEntryForm;
        form.requestSubmit();
    }

    addValue(event) {
        console.log(event);
    }

    propId(event) {
        this.parcelIdTarget.innerText = event.srcElement.value;
    }
}
