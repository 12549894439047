import { Controller } from '@hotwired/stimulus';
import axios from 'axios';


export default class extends Controller {
    static targets = ['parcelId', 'propertyBasicInfo', 'propertyId', 'offerorId'];

    connect() {
        console.log('add-offer');
        const propertyAutocomplete = document.getElementById("property-autocomplete");
        propertyAutocomplete.addEventListener("autocomplete.change", this.propertySelected.bind(this))
        const offerorAutocomplete = document.getElementById("offeror-autocomplete");
        offerorAutocomplete.addEventListener("autocomplete.change", this.offerorSelected.bind(this))
    }

    propertySelected(event) {
        event.preventDefault();
        this.propertyIdTarget.value = event.detail.value;
        const form = document.addOfferForm;
        form.requestSubmit();
    }

    offerorSelected(event) {
        event.preventDefault();
        this.offerorIdTarget.value = event.detail.value;
        const form = document.addOfferForm;
        form.requestSubmit();
    }

    addNewOfferor(event) {
        console.log(event);
    }

    addNewOffer(event) {
        console.log(event);
    }

    propId(event) {
        this.parcelIdTarget.innerText = event.srcElement.value;
    }
}
